import React, { useState } from 'react';
import config from '../config/config';

function CheckBalance({ balance }) {
    const [cardNumber, setCardNumber] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const getBalance = (e) => {
        e.preventDefault();

        // Create a payload object with the data you want to send
        const payload = {
            cardNumber,
            userName: config.userName,
            password: config.password,
        };
        const token = localStorage.getItem('jwtToken');
        const headers = {
            'Content-Type': 'application/json'
        };

        if (token)
        {
            headers['Authorization'] = `Bearer ${token}`;
        }
        console.log(payload);
        // Send POST request to the server
        fetch(`${config.apiBaseUrl}/Card/Balance`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (!response.ok)
                {
                    throw new Error('Failed to fetch card balance');
                }

                return response.json();
            })
            .then(responseData => {
                console.log(responseData);
                setSuccessMessage('Your current balance: $' + responseData.data);
                setCardNumber('');
                setErrorMessage('');
            })
            .catch(error => {
                console.error('Error fetching balance:', error);
                setErrorMessage('Failed to fetch card balance');
                setSuccessMessage('');
            });
    };

    return (
        <div className="container">
            <h2>Check Balance</h2>
            <form onSubmit={getBalance}>
                <div className="form-group">
                    <label id="lblCardNumber" class="form-label">Card Number:</label>
                    <input
                        type="number"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}
                        placeholder="Enter card number"
                        className="form-control w-50 mb-2"
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Get Balance</button>
            </form>
            {successMessage && <p>{successMessage}</p>}
            {errorMessage && <p>{errorMessage}</p>}
        </div >

    );
}

export default CheckBalance;