import React, { useState } from 'react';
import './App.css'; // Import custom CSS file
import Login from './components/Login';
import AddFunds from './components/AddFunds';
import CheckBalance from './components/CheckBalance';
import RedeemFunds from './components/RedeemFunds';
import TransactionHistory from './components/TransactionHistory';
import config from './config/config';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeComponent, setActiveComponent] = useState('CheckBalance');

  const handleLogin = async () => {
    // Logic for handling successful login
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Logic for handling logout
    localStorage.removeItem('jwtToken');
    setIsLoggedIn(false);
  };

  const handleMenuClick = (component) => {
    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'application/json'
    };

    if (token)
    {
      headers['Authorization'] = `Bearer ${token}`;
    }
    fetch(`${config.apiBaseUrl}/Auth/isTokenValid`, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok)
        {
          handleLogout();
        }
        setActiveComponent(component);
      })
      .catch(error => {
        handleLogout();
      });

  };

  return (
    <div className="container-fluid">
      <header className="bg-primary text-white py-2 text-center">
        <h3>Card Manager</h3>
      </header>
      {isLoggedIn && (
        <div className="row">
          <nav className="col-md-2 col-lg-2 d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <button className={`nav-link btn btn-link ${activeComponent === 'CheckBalance' ? 'active' : ''}`} onClick={() => handleMenuClick('CheckBalance')}>Check Balance</button>
                </li>
                <li className="nav-item">
                  <button className={`nav-link btn btn-link ${activeComponent === 'AddFunds' ? 'active' : ''}`} onClick={() => handleMenuClick('AddFunds')}>Add Funds</button>
                </li>
                <li className="nav-item">
                  <button className={`nav-link btn btn-link ${activeComponent === 'RedeemFunds' ? 'active' : ''}`} onClick={() => handleMenuClick('RedeemFunds')}>Redeem Funds</button>
                </li>
                <li className="nav-item">
                  <button className={`nav-link btn btn-link ${activeComponent === 'TransactionHistory' ? 'active' : ''}`} onClick={() => handleMenuClick('TransactionHistory')}>Transaction History</button>
                </li>
                <li className="nav-item">
                  <button className="btn btn-outline-primary" onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            </div>
          </nav>
          <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-md-4">
            <div className="content mt-4">
              {activeComponent === 'CheckBalance' && <CheckBalance />}
              {activeComponent === 'AddFunds' && <AddFunds />}
              {activeComponent === 'RedeemFunds' && <RedeemFunds />}
              {activeComponent === 'TransactionHistory' && <TransactionHistory />}
            </div>
          </main>
        </div>
      )}
      {!isLoggedIn && <Login onLogin={handleLogin} />}
    </div>
  );
};

export default App;
