import React, { useState } from 'react';
import config from '../config/config';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Basic validation
        if (username.trim() === '' || password.trim() === '')
        {
            setError('Please enter both username and password');
            return;
        }
        const payload = {
            userName: username,
            password: password
        };
        const response = await fetch(`${config.apiBaseUrl}/Auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
            // Add any other options as needed
        })
            .then(response => {
                if (!response.ok)
                {
                    setError('Login failed. Enter valid username and password');
                    throw new Error('Login failed. Enter valid username and password');
                }
                return response.json();
            })
            .then(dataPromise => {
                console.log(dataPromise);
                localStorage.setItem('jwtToken', dataPromise.data.token);
                onLogin();
            })
            .catch(error => {
                console.error('Login error:', error);
            });
    };

    return (
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Login</div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label id="username">Username:</label>
                                    <input
                                        type="text"
                                        id="username"
                                        className="form-control"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label id="password">Password:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        className="form-control mb-2"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                {error && <div className="alert alert-danger">{error}</div>}
                                <button type="submit" className="btn btn-primary">Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
