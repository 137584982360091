import React, { useState } from 'react';
import config from '../config/config';

function AddFunds() {
  const [amount, setAmount] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddFunds = (e) => {
    e.preventDefault();

    // Create a payload object with the data you want to send
    const payload = {
      amount: parseFloat(amount),
      cardNumber,
      userName: config.userName,
      password: config.password
    };
    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'application/json'
    };

    if (token)
    {
      headers['Authorization'] = `Bearer ${token}`;
    }
    // Send POST request to the server
    fetch(`${config.apiBaseUrl}/Transact/Topup`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (!response.ok)
        {
          throw new Error('Failed to add funds');
        }
        return response.json()
      })
      .then(result => {
        setSuccessMessage('Funds added successfully. Updated balance is: $' + result.data);
        setAmount(''); // Clear input field after successful request
        setCardNumber('');
        setErrorMessage('');
      })
      .catch(error => {
        console.error('Error adding funds:', error);
        setErrorMessage('Failed to add funds');
        setSuccessMessage('');
      });
  };

  return (
    <div className="container">
      <h2>Add Funds</h2>
      <form onSubmit={handleAddFunds}>
        <div className="form-group">
          <label id="lblCardNumber" class="form-label">Card Number:</label>
          <input
            type="number"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="Enter card number"
            className="form-control w-50"
            required
          />
        </div>
        <div>
          <label id="lblamount" class="form-label">Amount:</label>
          <input
            type="number"
            step="0.01"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            className="form-control w-50 mb-2"
            required
          />
        </div>
        <div><button type="submit" className="btn btn-primary">Add Funds</button></div>
      </form>
      {successMessage && <p>{successMessage}</p>}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
}

export default AddFunds;
