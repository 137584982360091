// TransactionHistory.js
import React, { useState } from 'react';
import config from '../config/config';

function TransactionHistory() {
    const [transactions, setTransactions] = useState([]);
    const [cardNumber, setCardNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleCardHistory = (e) => {
        e.preventDefault();
        const payload = {
            cardNumber,
            userName: config.userName,
            password: config.password
        };

        const token = localStorage.getItem('jwtToken');
        const headers = {
            'Content-Type': 'application/json'
        };

        if (token)
        {
            headers['Authorization'] = `Bearer ${token}`;
        }
        fetch(`${config.apiBaseUrl}/Card/History`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (!response.ok)
                {
                    throw new Error('Failed to fetch card balance');
                }

                return response.json();
            })
            .then(result => {
                setTransactions(result.data);
                setCardNumber('');
                setErrorMessage('');
            })
            .catch(error => {
                console.error('Error fetching card history:', error);
                setErrorMessage('Failed to fetch card transaction history');
            });
    };

    return (
        <div className="container">
            <h2>Transaction History</h2>
            <div>
                <form onSubmit={handleCardHistory}>
                    <div className="form-group">
                        <label id="lblCardNumber" class="form-label">Card Number:</label>
                        <input
                            type="number"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                            placeholder="Enter card number"
                            className="form-control w-50 mb-2"
                            required
                        />
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">Get Transaction History</button>
                    </div>
                </form>
                {errorMessage && <p>{errorMessage}</p>}
            </div>
            {transactions.length > 0 && (<table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>Transaction ID</th>
                        <th>Transaction Type</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Updated Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.transactionId}</td>
                            <td>{transaction.transactionType}</td>
                            <td>{transaction.transactionDate.toLocaleString()}</td>
                            <td>${transaction.amount.toFixed(2)}</td>
                            <td>${transaction.endBalance.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>)}
        </div>
    );
}

export default TransactionHistory;
